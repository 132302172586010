.logo {
    position: relative;
    z-index: 100;
    display: flex;
    align-items: center;
    text-align: center;
}

.centered {
    margin: 0 auto;
}

.biggerLogoText {
    font-size: 16px !important;
    line-height: 24px !important;
}
