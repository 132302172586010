.default {
    width: 100%;
    font-weight: 700;
    font-size: 20px;
    line-height: 60px;
    letter-spacing: 2px;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
}

.default:disabled {
    pointer-events: none;
}

.colorRed {
    color: red;
}

.primary {
    color: #212121;

    /*background-color: var(--decorative-safe);*/
}

.male {
    color: var(--surface-default);
    background-color: #e73939;
}

.primary:disabled {
    /*background-color: var(--brand-primary-disabled);*/
}

.inverse {
    /*color: var(--decorative-safe);*/

    /*border: 2px solid var(--decorative-safe);*/
}

.inverse:disabled {
    /*color: var(--brand-primary-disabled);*/

    /*border: 2px solid var(--brand-primary-disabled);*/
}

.iconed {
    position: relative;
    width: 100%;
    max-width: 165px;
    font-weight: 700;
    font-size: 20px;
    text-align: left;
    padding: 0 45px 0 16px;
}

.small {
    font-size: 12px;
    line-height: 16px;
    max-width: 138px;
    padding: 4px 0;
    height: 40px;
}

.bold {
    background-color: transparent;

    /*color: var(--decorative-safe);*/
}

.female {
    color: var(--surface-default);
    background-color: #7e97f1;
}

.paypalButton {
    /*background-color: var(--button-paypal-color);*/
    height: 48px;
    border-radius: 8px;
    position: relative;
}

.animation {
    width: calc(100% - 20px);
    animation: pulsar 2s infinite;
    margin: 0 auto;
    display: block;
    position: relative;
}

@keyframes pulsar {
    0% {
        /*box-shadow: 0 0 0 0 var(--decorative-safe);*/
    }

    70% {
        /*box-shadow: 0 0 0 14px var(--brand-primary-opacity-5);*/
    }

    100% {
        box-shadow: 0 0 0 14px transparent;
    }
}

.isOnePrice {
    margin-bottom: 20px;
}

.btnConsent {
    max-width: 200px;
    letter-spacing: 1px;
}
