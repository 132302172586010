/* ToDo: remove top padding from container in one of future refactor */

.default {
    padding: 86px var(--default-indent) 24px;
    max-width: 400px;
    margin: 0 auto;
    position: relative;
}

.withoutHeader {
    padding: 16px var(--default-indent) 24px;
}

.fullPadding {
    padding: 86px var(--default-indent) 106px;
}

.noMargin {
    max-width: unset;
    padding: 0 var(--default-indent) 0;
    background: #f9f9fa;
}

.flexContainer {
    display: flex;
}

.noBottomPadding {
    padding-bottom: 0;
}

.grayBG {
    background-color: var(--background-subdude);
}

@media screen and (min-width: 1024px) {
    .default {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: unset;
        position: relative;
    }

    .width368 {
        width: 368px;
        margin: 0 auto;
        padding-left: 0;
        padding-right: 0;
    }

    .justifyCenter {
        justify-content: center;
        padding-bottom: 86px;
    }
}
