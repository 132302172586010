.default {
    width: 100%;
    height: 60px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    padding: 16px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    flex-shrink: 0;
}

.default:disabled {
    pointer-events: none;
}

.colorRed {
    color: #f00;
}

.primary {
    color: var(--surface-default);

    /*background-color: var(--decorative-safe);*/
}

.primary:disabled {
    /*background-color: var(--brand-primary-disabled);*/
}

.inverse:disabled {
    /*color: var(--brand-primary-disabled);*/

    /*border: 2px solid var(--brand-primary-disabled);*/
}

.inverse {
    /*color: var(--decorative-safe);*/

    /*border: 2px solid var(--decorative-safe);*/
}

.rounded {
    border-radius: 12px;
}

.small {
    font-size: 12px;
    line-height: 16px;
    max-width: 138px;
    padding: 4px 0;
    height: 40px;
}

.bold {
    background-color: transparent;

    /*color: var(--decorative-safe);*/
}

.exclusive {
    /*background-color: var(--decorative-safe);*/
}

.female {
    color: var(--surface-default);
    background-color: var(--primary-default);
}

.maleFast {
    color: var(--surface-default);

    /*background-color: var(--logo-color-fast);*/
}

[class*='arrowed'] {
    display: flex;
    padding: 16px 20px;
    align-items: center;
    justify-content: space-between;
}

.arrowedLeft {
    flex-direction: row-reverse;
}

.arrowedLeft .arrow {
    transform: rotate(180deg);
}

.arrowedLeft [class*='loader'] {
    left: 22px;
}

.animation {
    width: calc(100% - 20px);
    animation: pulsar 2s infinite;
    margin: 0 auto;
    display: block;
    position: relative;
}

.animationFast {
    width: calc(100% - 20px);
    animation: pulsarFast 2s infinite;
    margin: 0 auto;
    display: block;
    position: relative;
}

.noMargin {
    margin: 0;
}

.skip {
    position: relative;
    display: block;
    width: unset;
    height: unset;
    margin: 8px auto 0;
    padding: 0;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;

    /*color: var(--on-surface-text-secondary-subdued);*/
    letter-spacing: unset;
    text-transform: unset;
    background: none;
}

.skip:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -1px;
    left: 0;
    height: 1px;
    background: #8696a6;
}

@keyframes pulsar {
    0% {
        /*box-shadow: 0 0 0 0 var(--decorative-safe);*/
    }

    100% {
        box-shadow: 0 0 0 14px transparent;
    }
}

.isOnePrice {
    margin-bottom: 20px !important;
}

.squareBtn {
    width: 138px;
    height: 40px;
    border-radius: 8px;
    letter-spacing: 1px;
}

.notificationBtn {
    width: 100%;
    height: 40px;
    margin-bottom: 0;
    padding: 0;
    background: #49d461;
    border-radius: 8px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
}

.btnConsent {
    max-width: 200px;
    letter-spacing: 1px;
}

.paypalButton {
    background-color: #f9c456;
}

.imageWrapper {
    max-width: 101px;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

@keyframes pulsar {
    0% {
        /*box-shadow: 0 0 0 0 var(--decorative-safe);*/
    }

    100% {
        box-shadow: 0 0 0 14px transparent;
    }
}

@keyframes pulsarFast {
    0% {
        /*box-shadow: 0 0 0 0 var(--logo-color-fast);*/
    }

    100% {
        box-shadow: 0 0 0 14px transparent;
    }
}

/* PULSE COLOR THEMSE */

.pulse {
    letter-spacing: unset;
    text-transform: capitalize;
}

.pulse.PulseOnemale {
    background-color: #acc3d7;
    animation-name: pulsarOneMale;
}

.pulse.PulseTwomale {
    background-color: #6cdaea;
    animation-name: pulsarTwoMale;
}

.pulse.PulseThreemale {
    background-color: #b8cc94;
    animation-name: pulsarThreeMale;
}

.pulse.PulseOnefemale,
.pulse.PulseThreefemale {
    background-color: #ffaeae;
    animation-name: pulsarOneFemale;
}

.pulse.PulseTwofemale {
    background-color: #a8b6fb;
    animation-name: pulsarTwoFemale;
}

.pulse.PulseFour {
    background-color: #ff4f64;
    animation-name: pulsarFour;
}

@keyframes pulsarOneMale {
    0% {
        box-shadow: 0 0 0 0 #acc3d7;
    }

    100% {
        box-shadow: 0 0 0 14px transparent;
    }
}

@keyframes pulsarTwoMale {
    0% {
        box-shadow: 0 0 0 0 #6cdaea;
    }

    100% {
        box-shadow: 0 0 0 14px transparent;
    }
}

@keyframes pulsarThreeMale {
    0% {
        box-shadow: 0 0 0 0 #b8cc94;
    }

    100% {
        box-shadow: 0 0 0 14px transparent;
    }
}

@keyframes pulsarOneFemale {
    0% {
        box-shadow: 0 0 0 0 #ffaeae;
    }

    100% {
        box-shadow: 0 0 0 14px transparent;
    }
}

@keyframes pulsarTwoFemale {
    0% {
        box-shadow: 0 0 0 0 #a8b6fb;
    }

    100% {
        box-shadow: 0 0 0 14px transparent;
    }
}

@keyframes pulsarFour {
    0% {
        box-shadow: 0 0 0 0 #ff4f64;
    }

    100% {
        box-shadow: 0 0 0 14px transparent;
    }
}

/* END OG PULSE COLOR THEME */

/* ToDo: experiment 24.09 */
.roundExp {
    font-size: 12px !important;
    line-height: 16px;
    letter-spacing: 1px !important;
    text-transform: uppercase;
    height: auto;
    border-radius: 14px;
    padding: 6px 12px;
}
