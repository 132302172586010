.top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.iconBack {
    cursor: pointer;
}

.pageCounter {
    font-weight: 700;
    font-size: 12px;
    display: flex;
}

.progressBox {
    margin-top: 8px;
    position: relative;
    width: 100%;
    height: 6px;
    border-radius: 5px;
    background-color: var(--surface-default);
}

.progressBar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 5px;
    background-color: var(--primary-default);
    transition: width 0.2s linear;
}
